@import './variables';
@import './utilities';
@import './grid';
@import './typography';

@include generate-color-classes();

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: color('secondary-beige');
  font-family: 'Nunito';
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

button {
  cursor: pointer;
  justify-content: center;
  white-space: nowrap;
  border: none;
  font-family: inherit;
  font-size: 16px;
  padding: 0;
  background: none;
  color: color('primary-black');

  &:focus {
    outline: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  margin-top: spacing('xl');

  @include breakpoint('small') {
    margin-top: module-spacing('s');
  }
}

section {
  & + section {
    margin-top: spacing('xxl');

    @include breakpoint('small') {
      margin-top: module-spacing('s');
    }
  }
}

.icon-base {
  transition: color 0.2s;

  path {
    fill: none;
    stroke-width: 3px;
    vector-effect: non-scaling-stroke;
  }
}

.button {
  position: relative;
  display: inline-flex;
  text-decoration: none;
  transform: translateY(-5px);
  background: color('primary-yellow');
  padding: 0 17px;
  min-height: 54px;
  color: color('primary-black');
  border: 3px solid color('primary-black');
  border-radius: 26px;
  overflow: visible;
  transition: transform 0.1s ease-out, background-color 0.1s ease-out, border-color 0.1s ease-out, box-shadow 0.1s ease-out;
  font-size: 18px;
  font-family: 'GT Maru';
  text-align: center;
  outline: 0;
  cursor: pointer;
  @include var-font-weight(600);

  @include breakpoint('small') {
    font-size: 20px;
  }

  &:visited {
    color: color('primary-black');
  }

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 28px;
    box-shadow: 0 5px 0 1px color('primary-black');
    transition: box-shadow 0.1s ease-out;
  }

  @include hover-only() {
    &:hover:not(:disabled) {
      transform: translateY(0);

      &:after {
        box-shadow: 0 0 0 0 color('primary-black');
      }
    }
  }

  &.clicked {
    transform: translateY(3px) !important;

    &:after {
      box-shadow: 0 0 0 0 color('primary-black');
    }
  }

  &:disabled,
  [disabled] {
    transform: translateY(0);
    color: color('secondary-grey');
    background-color: color('secondary-grey-pale');
    border-color: color('secondary-grey');
    cursor: not-allowed;

    &:after {
      box-shadow: none;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 6px color('primary-blue');
    transform: translateY(0);

    &:after {
      box-shadow: 0 0 0 0 color('primary-black');
    }
  }

  @include breakpoint('medium', 'max') {
    min-height: 44px;
    border-radius: 22px;
    padding: 0 spacing('s');

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .button-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    width: 100%;

    .caption-copy {
      transform: translateY(-1px);
    }
  }

  .icon {
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-left: spacing('xs');
  }
}

#intro {
  h1 {
    margin-bottom: spacing('l');
  }

  .logo {
    display: flex;
    height: 50px;
    margin-bottom: spacing('l');

    @include breakpoint('medium') {
      height: 60px;
    }

    svg {
      display: block;
      height: 100%;
      width: auto;
      overflow: visible;
    }

    .icon {
      circle,
      path {
        stroke: color('primary-black');
        stroke-width: 3px;
        vector-effect: non-scaling-stroke;
      }
    }

    .circle {
      fill: color('primary-yellow');
    }

    .rectangle {
      fill: color('primary-red');
    }

    .triangle {
      fill: color('primary-blue');
    }
  }

  .video {
    margin-top: spacing('xl');

    @include breakpoint('small') {
      margin-top: module-spacing('s');
    }
  }
}

.video {
  .iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    background-color: color('primary-black');
    border-radius: 16px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      border: 3px solid color('primary-black');
      pointer-events: none;
      border-radius: 16px;
    }
  }

  iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cta {
    margin-top: spacing('l');
    display: flex;
    gap: spacing('s') spacing('xs');
    flex-wrap: wrap;
  }
}

#solution {
  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }
}

#testimonials {
  .slider {
    &:not([data-keen-slider-disabled]) {
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
      align-content: flex-start;
      display: flex;
      overflow: hidden;
      position: relative;
      touch-action: pan-y;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -khtml-user-select: none;
      width: 100%;
      margin-top: spacing('l');
    }

    &:not([data-keen-slider-disabled]) .slide {
      min-height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    &:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
      flex-direction: row-reverse;
    }

    &:not([data-keen-slider-disabled])[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    [data-keen-slider-moves] * {
      pointer-events: none;
    }
  }

  .slider-nav-pages {
    margin-top: spacing('l');
    display: flex;
    width: 100%;
    gap: spacing('s');
    align-items: center;
    font-weight: font-weight(700);
    font-size: 18px;
    transform: translateX(-14px);

    .page-info {
      text-align: center;
      width: 50px;
    }
  }

  .author {
    font-size: 18px;
    margin-top: spacing('l');

    .author-name {
      font-weight: font-weight(700);
    }
  }

  button {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled,
    [disabled] {
      .icon-base {
        color: color('secondary-grey');
      }
    }

    @include hover-only() {
      &:hover {
        color: color('primary-blue');
      }
    }
  }
}

footer {
  text-align: center;
  margin: spacing('xxl') 0 spacing('xl') 0;

  @include breakpoint('small') {
    margin: module-spacing('m') 0 spacing('xxl') 0;
  }

  .icon {
    display: inline-block;
    margin-bottom: spacing('xxs');
    width: 44px;

    @include breakpoint('small') {
      width: 60px;
    }
  }

  .tagline {
    font-size: 16px;
    font-weight: font-weight(700);

    @include breakpoint('small') {
      font-size: 18px;
    }
  }
}
