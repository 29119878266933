@include font-face('Nunito', '../assets/fonts/nunito/nunito-v24-latin-regular', 400, normal, woff woff2 ttf eot svg);
@include font-face('Nunito', '../assets/fonts/nunito/nunito-v24-latin-600', 600, normal, woff woff2 ttf eot svg);
@include font-face('Nunito', '../assets/fonts/nunito/nunito-v24-latin-700', 700, normal, woff woff2 ttf eot svg);

@font-face {
  font-family: 'GT Maru';
  src: url('../assets/fonts/gt-maru/GT-Maru-Proportional.woff2') format('woff2-variations'),
    url('../assets/fonts/gt-maru/GT-Maru-Proportional.ttf') format('truetype-variations');
  font-weight: normal;
}

h1,
h2,
h3 {
  font-family: 'GT Maru';
  font-weight: normal;
  line-height: 1.2em;
  @include var-font-weight(600);
}

h1 {
  font-size: 34px;
  line-height: 1.1em;

  @include breakpoint('medium') {
    font-size: 48px;
  }

  @include breakpoint('large') {
    font-size: 64px;
  }
}

h2 {
  font-size: 28px;

  @include breakpoint('medium') {
    font-size: 35px;
  }

  & + p {
    margin-top: spacing('l');
  }
}

p,
ul {
  font-size: 18px;
  line-height: 1.6em;

  @include breakpoint('medium') {
    font-size: 22px;
    line-height: 1.5em;
  }

  @include breakpoint('large') {
    font-size: 24px;
  }

  & + p {
    margin-top: 20px;
  }
}

p + ul {
  margin-top: 20px;
}

a {
  color: color('secondary-blue-dark');
  text-decoration: underline;
  text-underline-offset: 0.2em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  &:visited {
    color: color('secondary-blue-dark');
  }
}
